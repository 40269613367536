<template>
	<div id="app">
		<form class="form" @submit.prevent="submit">
			<h2 class="form-title">Служба поддержки Aula.kz</h2>
			<p class="form-text">
				У вас есть вопросы? Не стесняйтесь обращаться к нам напрямую. Наша
				команда свяжется с вами в течение нескольких часов, чтобы помочь вам.
			</p>
			<hr />
			<div class="form-group">
				<div class="form-field-name">Ваше имя</div>
				<input
					v-model.trim="$v.name.$model"
					type="text"
					class="form-field"
					:class="{ 'form-field-error': $v.name.$error }"
				/>
				<legend v-if="$v.name.$error" class="form-field-hint">
					Минимальная длина {{ $v.name.$params.minLength.min }}
				</legend>
			</div>
			<div class="form-group">
				<div class="form-field-name">Ваш Email</div>
				<input
					v-model="$v.email.$model"
					type="text"
					class="form-field"
					:class="{ 'form-field-error': $v.email.$error }"
					placeholder="email@example.com"
				/>
				<legend v-if="$v.email.$error" class="form-field-hint">
					Введите корректный Email
				</legend>
			</div>
			<div class="form-group">
				<div class="form-field-name">Тема сообщения</div>
				<input
					v-model.trim="$v.subject.$model"
					type="text"
					class="form-field"
					:class="{ 'form-field-error': $v.subject.$error }"
				/>
				<span class="field-counter">
					{{ subjectCounter }} / {{ $v.subject.$params.maxLength.max }}
				</span>
				<legend v-if="$v.subject.$error" class="form-field-hint">
					Длина темы должна быть от {{ $v.subject.$params.minLength.min }} до
					{{ $v.subject.$params.maxLength.max }}
				</legend>
			</div>
			<div class="form-group">
				<div class="form-field-name">Текст сообщения</div>
				<textarea
					v-model.trim="$v.text.$model"
					type="text"
					class="form-field"
					:class="{ 'form-field-error': $v.text.$error }"
				/>
				<span class="field-counter">{{ textCounter }} / 500</span>
				<legend v-if="$v.text.$error" class="form-field-hint">
					Длина текста должна быть от {{ $v.text.$params.minLength.min }} до
					{{ $v.text.$params.maxLength.max }}
				</legend>
			</div>
			<div v-if="alert" class="alert" :class="alert.color">
				{{ alert.message }}
			</div>
			<div class="buttons">
				<button class="form-button" type="submit">
					<span v-if="!loading">Отправить</span>
					<Loader v-else />
				</button>
				<a class="home-link" href="https://aula.kz/">Главная Aula.kz</a>
			</div>
			<p v-if="$v.$invalid" class="warning">
				Пожалуйста, заполните форму корректно!
			</p>
		</form>
	</div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader";

import {
	required,
	minLength,
	maxLength,
	email,
} from "vuelidate/lib/validators";

export default {
	name: "App",
	components: {
		Loader,
	},
	data() {
		return {
			alert: null,
			name: "",
			email: "",
			loading: false,
			subject: "",
			text: "",
			submitStatus: true,
		};
	},
	computed: {
		textCounter() {
			return this.$v.text.$model.trim().length;
		},
		subjectCounter() {
			return this.$v.subject.$model.trim().length;
		},
	},
	validations: {
		email: { required, email },
		name: { required, minLength: minLength(2) },
		subject: {
			required,
			minLength: minLength(5),
			maxLength: maxLength(50),
		},
		text: { required, minLength: minLength(10), maxLength: maxLength(500) },
	},
	methods: {
		submit() {
			this.alert = null;
			this.$v.$touch();
			if (this.$v.$invalid) {
				this.submitStatus = false;
				return;
			}

			this.loading = true;
			this.submitStatus = true;

			const data = {
				email: this.$v.email.$model,
				name: this.$v.name.$model,
				subject: this.$v.subject.$model,
				text: this.$v.text.$model,
			};

			axios
				.post("/email", data)
				.then(res => {
					this.alert = {
						color: "success",
						message: res.data.message || "Сообщение успешно отправлено",
					};
				})
				.catch(err => {
					console.log(err);
					this.alert = {
						color: "error",
						message: err.message || "Повторите отправку",
					};
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Rubik", Avenir, sans-serif;
}
html,
body {
	height: 100vh;
	width: 100vw;
}

#app {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}
.alert {
	margin-top: 30px;
	border-radius: 4px;
	font-size: 18px;
	color: #fff;
}
.alert.success {
	color: #56bb8a;
}
.alert.error {
	color: #bb5656;
}
.home-link {
	margin-left: 20px;
	color: #56bb8a;
	text-decoration: none;
}
.warning {
	margin-top: 20px;
	color: #bb5656;
}
.form {
	margin: auto;
	padding: 30px 20px;
	width: 100%;
	max-width: 600px;
}
hr {
	margin-top: 20px;
	border: none;
	height: 1px;
	/* Set the hr color */
	color: #bababa; /* old IE */
	background-color: #bababa; /* Modern Browsers */
}
.form-text {
	margin-top: 20px;
	line-height: 25px;
	color: #495057;
}
.form-group {
	margin-top: 20px;
}
.form-field {
	width: 100%;
	height: 48px;
	padding: 10px 16px 12px;
	font-family: "Rubik", Helvetica, Arial, sans-serif;
	font-size: 18px;
	border: 1px solid transparent;
	transition: border 0.3s;
	box-shadow: 0px 5px 30px rgb(0 0 0 / 10%);
	border-radius: 5px;
	color: #000;
}
.form-field-error {
	border-color: #bb5656;
}
.form-field-error:focus,
.form-field-error:hover {
	border-color: #bb5656 !important;
}

textarea:focus,
input:focus {
	outline: none;
}
.form-field:active,
.form-field:focus {
	border-color: #56bb8a;
	box-shadow: none;
	transition: none;
}
.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.form-field:hover {
	border-color: #56bb8a;
	transition: none;
}
/* Hint */
.form-field-hint {
	margin-top: 5px;
	color: #bb5656;
	font-size: 14px;
}
.form-field-name {
	margin-bottom: 5px;
}
textarea.form-field {
	resize: none;
	min-width: 300px;
	height: 150px;
}
.field-counter {
	font-size: 12px;
	color: #292929;
	float: right;
}
.form-button {
	position: relative;
	margin-top: 20px;
	outline: none;
	border: none;
	padding: 9px 15px 9px;
	font-size: 18px;
	font-weight: 400;
	color: #fff;
	background: #56bb8a;
	height: 48px;
	box-shadow: 0px 5px 30px rgb(0 0 0 / 10%);
	border-radius: 5px;
	cursor: pointer;
	transition: opacity 0.3s;
}
.form-button:hover {
	opacity: 0.6;
}
</style>
